import { mapActions, mapGetters } from 'vuex';

import { WarningMessage } from '@/types/nextServiceInfo';
import { defineComponent } from 'vue';
import mapService from '@/services/map';

export default defineComponent({
   data() {
      return {
         activeVehicleTrack: false,
         activeVehicleOriginDestinationTrack: true,
      };
   },
   computed: {
      ...mapGetters('metro', ['metroStationGeoJson']),
      ...mapGetters('nextService', [
         'vehicleGeoJson',
         'originGeoJson',
         'destinationGeoJson',
         'typeVehicle',
         'trip',
      ]),
      ...mapGetters([
         'serviceError',
      ]),
      originOrDestination(): string {
         const displayDestination = this.destinationGeoJson ? !!this.destinationGeoJson.features[0].properties.display : false;
         return displayDestination ? this.destinationGeoJson : this.originGeoJson;
      },
      warningMessage(): WarningMessage {
         if (this.serviceError) {
            return this.serviceError;
         }
         return this.trip?.warningMessage;
      },
   },
   watch: {
      metroStationGeoJson(metroGeoJSONFeatureCollection) {
         if (metroGeoJSONFeatureCollection) {
            mapService.setMetro(metroGeoJSONFeatureCollection);
         }
      },
      vehicleGeoJson(data) {
         // Adding them enables bus animation
         if (this.activeVehicleTrack) {
            mapService.recenterTwoPoints(data, null);
         } else if (this.activeVehicleOriginDestinationTrack) {
            mapService.recenterTwoPoints(data, this.originOrDestination);
         }
      },
      typeVehicle(data) {
         // Adding them enables bus animation
         mapService.addVehicleControl(data);
         this.activeVehicleTrack = false;
      },
      warningMessage(data, oldData) {
         if (data !== oldData) {
            if (data) {
               this.activeTrack(false, false);
               mapService.disableAllButtons(true);
            } else {
               this.activeTrack(false, true);
               mapService.recenterTwoPoints(this.vehicleGeoJson, this.originOrDestination);
               mapService.disableAllButtons(false);
            }
         }
      },
   },
   unmounted() {
      mapService.setGeolocationHandler(null);
      this.activeTrack(false, true);
   },
   mounted() {
      mapService.setTarget('map');

      mapService.setGeolocationDestinationHandler(() =>
         this.setPositionDestination(),
      );
      mapService.setGeolocationBusHandler(() => this.setPositionBus());
      mapService.setGeolocationPopUpBusHandler(() => this.setPositionBus());
      mapService.map.render();
      mapService.addVehicleControl(this.typeVehicle);
      mapService.enablemapMoveEndListener({ mapMoveEndListener: this.mapMoveEnd });
   },
   methods: {
      ...mapActions('nextService', [
         'setIsTrackingActive',
      ]),
      setPositionBus() {
         this.activeTrack(!this.activeVehicleTrack, false);
         mapService.recenterTwoPoints(this.vehicleGeoJson, null);
      },
      setPositionDestination() {
         this.activeTrack(false, !this.activeVehicleOriginDestinationTrack);
         mapService.recenterTwoPoints(this.vehicleGeoJson, this.originOrDestination);
      },
      mapMoveEnd() {
         this.activeTrack(false, false);
      },
      activeTrack(activeVehicleTrack: boolean, activeVehicleOriginDestinationTrack: boolean) {
         this.setIsTrackingActive(activeVehicleTrack || activeVehicleOriginDestinationTrack);
         this.activeVehicleTrack = activeVehicleTrack;
         this.activeVehicleOriginDestinationTrack = activeVehicleOriginDestinationTrack;
         mapService.setStatusOriginDestinationControl(this.activeVehicleOriginDestinationTrack);
         mapService.setStatusVehicleControl(this.activeVehicleTrack);
      },
   },
});
