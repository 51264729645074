import $ from 'jquery';
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
   name: 'Menu',
   components: {
   },
   data() {
      return {
         isLoading: true,
         timer: 0,
         time_refresh: process.env.VUE_APP_TIME_REFRESH,
         loadingPromise: null as unknown as Promise<void>,
      };
   },
   computed: {
      ...mapGetters([
         'locale',
      ]),
      ...mapGetters('menu', [
         'firstTabs',
         'secondTabs',
         'footerTabs',
      ]),
   },
   methods: {
      ...mapActions([
         'setLocale',
      ]),
      switchLang(locale: string) {
         this.setLocale(locale);
         this.updateCookieAccessibiltyLinkText();
      },
      logOut() {
         window.location.replace('account/logout');
      },
      openCookiePopup() {
         const cookieLang = this.locale === 'fr-CA' ? 'fr-ca' : 'en';
         (window as any).illow.setLanguage(cookieLang);
         (window as any).illow.showWidget();

         const iframe = $('#illow-banner-widget')[0];
         (iframe as any).contentWindow.focus();

         this.updateCookiePopupLang();
      },
      updateCookiePopupLang() {
         const cookieLang = this.locale === 'fr-CA' ? 'fr-ca' : 'en';
         (window as any).illow.setLanguage(cookieLang);
      },
      updateCookieAccessibiltyLinkText() {
         const aEl = $('nav#skip-links ul li a')[0];
         (aEl as any).innerHTML = this.$t('cookiesPopup.accessibilityLinkText');
         this.updateCookiePopupLang();
      },
   },
});
