import { WarningMessage, WarningMessageId } from '@/types/nextServiceInfo';
import { mapActions, mapGetters } from 'vuex';

import BaseMap from '@/components/baseMap/baseMap.vue';
import Loader from '@/components/common/loader/loader.vue';
import { defineComponent } from 'vue';
import { event } from 'vue-gtag';
import mapService from '@/services/map';

export default defineComponent({
   name: 'Home',
   components: {
      BaseMap,
      'stm-loader': Loader,
   },
   data() {
      return {
         isLoading: true,
         timer: null as unknown as number,
         time_refresh: process.env.VUE_APP_TIME_REFRESH,
         loadingPromise: null as unknown as Promise<void>,
         mapService,
         WarningMessageId,
         customerIdentifier: null,
      };
   },
   computed: {
      ...mapGetters('nextService', [
         'trip',
         'vehicleGeoJson',
         'originGeoJson',
         'destinationGeoJson',
      ]),
      ...mapGetters('metro', ['metroStationGeoJson']),
      ...mapGetters([
         'serviceError',
      ]),
      ...mapGetters('account', [
         'customerId',
         'isAdmin',
      ]),
      originOrDestination(): string {
         const displayDestination = this.destinationGeoJson ? !!this.destinationGeoJson.features[0].properties.display : false;
         return displayDestination ? this.destinationGeoJson : this.originGeoJson;
      },
      etaEstimation(): string {
         return this.warningMessageId === WarningMessageId.ConnectionLost ? '≈' : '';
      },
      etaOrigin(): string {
         return this.originGeoJson ? `${this.etaEstimation}${this.originGeoJson.features[0].properties.eta}` : '';
      },
      etaDestination(): string {
         return this.destinationGeoJson ? `${this.etaEstimation}${this.destinationGeoJson.features[0].properties.eta}` : '';
      },
      warningMessage(): WarningMessage {
         if (this.serviceError) {
            return this.serviceError;
         }
         return this.trip?.warningMessage;
      },
      warningMessageId(): WarningMessageId {
         return this.warningMessage?.id;
      },
      hiddenWarningMessage(): string {
         return this.warningMessage ? `${this.$t('warning')} ${this.warningMessage.message}` : '';
      },
      hiddenETAOrigin(): string {
         return this.etaOrigin ? `${this.$t('home.hiddenETAOrigin')} ${this.etaOrigin}` : '';
      },
      hiddenETADestination(): string {
         return this.etaDestination ? `${this.$t('home.hiddenETADestination')} ${this.etaDestination}` : '';
      },
      message(): string {
         return this.trip?.tripMessage;
      },
      hiddenTripMessage(): string {
         return this.message ? `${this.$t('home.hiddenTripMessage')} ${this.message}` : '';
      },
      hiddenIntersections(): string {
         let msg = '';
         const vehicleProperties = this.vehicleGeoJson?.features[0]?.properties;
         if (vehicleProperties && vehicleProperties.currentStreet) {
            msg = vehicleProperties.nearStreetIntersection
               ? this.$t('home.hiddenIntersection', [vehicleProperties.currentStreet, vehicleProperties.nearStreetIntersection])
               : this.$t('home.hiddenIntersectionShort', [vehicleProperties.currentStreet]);
         }
         return msg;
      },
      isOrigin(): boolean {
         return this.originGeoJson ? this.originGeoJson.features[0].properties.display : false;
      },
      isDestination(): boolean {
         return this.destinationGeoJson ? this.destinationGeoJson.features[0].properties.display : false;
      },
   },
   watch: {
      vehicleGeoJson(data) {
         // Adding them enables bus animation
         mapService.setProximityBus(data);
      },
      originGeoJson(data, oldData) {
         if (JSON.stringify(data) !== JSON.stringify(oldData)) {
            mapService.setProximityOrigin(data);
         }
      },
      destinationGeoJson(data, oldData) {
         // Adding them enables bus animation
         if (JSON.stringify(data) !== JSON.stringify(oldData)) {
            mapService.setProximityDestination(data);
         }
      },
   },
   created() {
      event('Connexion', {
         event_category: 'Numéro de dossier',
         event_label: this.customerId,
      });
   },
   activated() {
      this.initialize();
   },
   deactivated() {
      this.stopTimer();
   },
   methods: {
      ...mapActions('nextService', [
         'getPosition',
      ]),
      ...mapActions(['setCustomerIdentifier']),
      fetchNextServiceInfo() {
         return this.getPosition();
      },
      startTimer() {
         this.timer = setInterval(this.fetchNextServiceInfo, this.time_refresh);
      },
      stopTimer() {
         clearInterval(this.timer);
      },
      resetTimer() {
         this.stopTimer();
         this.startTimer();
      },
      impersonalize() {
         if (this.customerIdentifier) {
            this.setCustomerIdentifier(this.customerIdentifier);
            this.resetTimer();
            this.fetchNextServiceInfo();
         }
      },
      initialize() {
         if (!this.customerIdentifier) {
            this.customerIdentifier = this.customerId;
            this.setCustomerIdentifier(this.customerIdentifier);
         }

         const promises = [] as Promise<any>[];
         promises.push(this.fetchNextServiceInfo());
         this.loadingPromise = Promise.allSettled([
            ...promises,
         ]).then(() => {
            mapService.recenterTwoPoints(this.vehicleGeoJson, this.originOrDestination);
            this.startTimer();
            mapService.setMetro(this.metroStationGeoJson);
            event('login', { method: this.customerIdentifier });
            this.isLoading = false;
         });
      },
   },
});
