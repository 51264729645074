import { Promised } from 'vue-promised';
import { defineComponent } from 'vue';

export default defineComponent({
   name: 'Loader',
   components: {
      promised: Promised,
   },
   props: {
      promise: {
         type: Promise,
         default: null,
      },
   },
});
