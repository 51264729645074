import { mapActions, mapGetters } from 'vuex';

import Loader from '@/components/common/loader/loader.vue';
import { Page } from '@/router';
import { WarningMessageId } from '@/types/nextServiceInfo';
import { defineComponent } from 'vue';

export default defineComponent({
   name: 'Home',
   components: {
      'stm-loader': Loader,
   },
   data() {
      return {
         isLoading: true,
         timer: null as unknown as number,
         time_refresh: process.env.VUE_APP_TIME_REFRESH,
         loadingPromise: null as unknown as Promise<void>,
      };
   },
   computed: {
      ...mapGetters('nextService', [
         'trip',
         'originGeoJson',
         'destinationGeoJson',
         'vehicleGeoJson',
      ]),
      ...mapGetters([
         'serviceError',
         'customerIdentifier',
      ]),
      ...mapGetters('account', [
         'customerId',
      ]),
      addressOrigin(): string {
         return this.originGeoJson ? this.originGeoJson.features[0].properties.address : '';
      },
      vehicleType(): string {
         return this.vehicleGeoJson && this.vehicleGeoJson.features[0].properties.type !== 'Unknown' ? this.vehicleGeoJson.features[0].properties.type : '';
      },
      addressDestination(): string {
         return this.destinationGeoJson ? this.destinationGeoJson.features[0].properties.address : '';
      },
      hiddenOriginAddress(): string {
         return this.addressOrigin ? `${this.$t('information.hiddenOriginAddress')} ${this.addressOrigin}` : '';
      },
      hiddenDestinationAddress(): string {
         return this.addressDestination ? `${this.$t('information.hiddenDestinationAddress')} ${this.addressDestination}` : '';
      },
      hiddenBoardingRange(): string {
         return this.trip.earliestPickUpTime ? this.$t('information.hiddenBoardingRange', [this.trip.earliestPickUpTime, this.trip.latestPickUpTime]) : '';
      },
      hiddenVehicleType(): string {
         return this.vehicleType ? `${this.$t('information.hiddenVehicleType')} ${this.vehicleType}` : '';
      },
      warningMessageId(): WarningMessageId {
         const error = this.serviceError ?? this.trip?.warningMessage;
         return error?.id;
      },
   },
   watch: {
      warningMessageId(data) {
         if (data && (data === WarningMessageId.NoTrip || data === WarningMessageId.Undefined)) {
            this.$router.push({ name: Page.Home });
         }
      },
   },
   activated() {
      this.initialize();
   },
   deactivated() {
      clearInterval(this.timer);
   },
   methods: {
      ...mapActions('nextService', [
         'getPosition',
      ]),
      ...mapActions(['setCustomerIdentifier']),
      fetchNextServiceInfo() {
         return this.getPosition();
      },
      initialize() {
         if (!this.customerIdentifier) {
            this.setCustomerIdentifier(this.customerId);
         }

         const promises = [] as Promise<any>[];
         promises.push(this.fetchNextServiceInfo());
         this.loadingPromise = Promise.allSettled([
            ...promises,
         ]).then(() => {
            this.timer = setInterval(this.fetchNextServiceInfo, this.time_refresh);
            this.isLoading = false;
         });
      },
   },
});
